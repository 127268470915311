<template>
  <div class="px-8 mt-4">
    <v-dialog
      v-model="dialogModel"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="poModal"
    >
      <v-card>
        <v-toolbar dark color="green darken-2">
          <v-toolbar-title>
            {{ prData.PRCODE }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          Grand Total: ₱ {{ formatPrice(prData.GrandTotal)  }}
          <v-btn icon dark @click="clickCloseMainDialogPR()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col lg="12" class="">
              <v-data-table
                v-model="formData.Lists"
                :headers="headers"
                :items="itemList"
                item-key="ppmpItemID"
                class="elevation-1 mt-4"
                disable-pagination
                hide-default-footer
                show-select
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="red darken-2"
                      outlined
                      class="mb-2 ml-4 mt-2 mr-2"
                      @click="reimburse()"
                      :disabled="!formData.Lists.length"
                    >
                      Reimburse
                    </v-btn>
                    <v-btn
                      color="blue darken-2"
                      outlined
                      class="mb-2 ml-4 mt-2 mr-2"
                      @click="cashAdvance()"
                      :disabled="!formData.Lists.length"
                    >
                      Cash Advance
                    </v-btn>
                    <v-btn
                      color="amber darken-2"
                      outlined
                      class="mb-2 ml-4 mt-2"
                      @click="gasCharge()"
                      :disabled="!formData.Lists.length"
                    >
                      Gas Charge
                    </v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.itemDescription`]="{ item }">
                  <v-edit-dialog
                    large
                    :return-value.sync="item.itemDescription"
                  >
                    {{ item.itemDescription }}
                    <template v-slot:input>
                      <v-textarea v-model="item.itemDescription">
                      </v-textarea>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.UnitCost`]="{ item }">
                  <v-edit-dialog v-if="item.SubItems.length == 0" large :return-value.sync="item.UnitCost">
                    <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost) }}</span>
                    <template v-slot:input>
                      <v-text-field v-model="item.UnitCost" type="number">
                      </v-text-field>
                    </template>
                  </v-edit-dialog>
                  <span v-if="item.SubItems.length > 0">₱ {{ formatPrice(item.UnitCost) }}</span>
                </template>
                <template v-slot:[`item.OverallQty`]="{ item }">
                  <!-- <v-edit-dialog large :return-value.sync="item.Qty"> -->
                      {{ formatQty(item.Qty) }}
                      <!-- <template v-slot:input>
                        <v-text-field v-model="item.Qty" type="number">
                        </v-text-field>
                      </template>
                    </v-edit-dialog> -->
                </template>
                <template v-slot:[`item.allowableQty`]="{ item }">
                      {{ formatQty(item.allowableQty) }}
                </template>
                <template v-slot:[`item.Qty`]="{ item }">
                  <span v-if="item.SubItems.length == 0">
                    <v-edit-dialog
                        large
                        :return-value.sync="item.editableQty"
                      >
                        <span
                          :class="
                            parseFloat(item.editableQty) +
                              parseFloat(item.IARQTY) >
                            parseFloat(item.Qty) || parseFloat(item.editableQty) <= 0
                              ? 'red--text'
                              : ''
                          "
                        >
                          <span :class="
                            parseFloat(item.editableQty) +
                              parseFloat(item.IARQTY) >
                            parseFloat(item.Qty) || parseFloat(item.editableQty) <= 0
                              ? 'red--text'
                              : 'green--text'
                          ">{{ formatQty(item.editableQty) }}</span>
                        </span>
                        <template v-slot:input>
                          <v-text-field
                            v-model="item.editableQty"
                            type="number"
                          >
                          </v-text-field>
                        </template>
                      </v-edit-dialog>
                    </span>
                  <span v-else>
                      <span :class="
                        parseFloat(item.editableQty) +
                          parseFloat(item.IARQTY) >
                        parseFloat(item.Qty) || parseFloat(item.editableQty) <= 0
                          ? 'red--text'
                          : 'green--text'
                      ">{{ formatQty(item.editableQty) }}
                      </span>
                  </span>
                </template>
                <template v-slot:[`item.ActualAmount`]="{ item }">
                  <span class="text-no-wrap">
                    ₱ {{formatPrice(parseFloat(item.UnitCost) * parseFloat(item.Qty))}}
                  </span>
                </template>
                <template v-slot:[`item.Amount`]="{ item }">
                  <span :class="['text-no-wrap', (parseFloat(item.UnitCost) * parseFloat(item.editableQty)) <= 0
                              ? 'red--text'
                              : 'green--text']">
                    ₱ {{formatPrice(parseFloat(item.UnitCost) * parseFloat(item.editableQty))}}
                  </span>
                </template>
                <template v-slot:[`item.Balance`]="{ item }">
                  <span :class="['text-no-wrap', (parseFloat(item.UnitCost) * (parseFloat(item.Qty)) - (parseFloat(item.UnitCost) * (parseFloat(item.editableQty) + parseFloat(item.IARQTY)))) < 0
                              ? 'red--text'
                              : 'green--text']">
                    ₱ {{formatPrice((parseFloat(item.UnitCost) * (parseFloat(item.Qty)) - (parseFloat(item.UnitCost) * (parseFloat(item.editableQty) + parseFloat(item.IARQTY)))))}}
                  </span>
                </template>
                <template v-slot:[`item.subItems`]="{ item, index }">
                  <v-btn
                  outlined
                  color="green darken-2"
                  small
                  @click="subItems(index, item)"
                  :disabled="parseFloat(item.allowableQty) <= 0"
                >
                <v-icon left>
                  mdi-plus
                </v-icon>
                  Items ({{item.SubItems.length}})
                </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="PurposeDialog" persistent max-width="600px" scrollable>
      <v-card>
        <v-toolbar dark color="green darken-2">
          <v-toolbar-title>
            <span v-if="iarTypeID==2">Reimbursement</span>
            <span v-if="iarTypeID==3">Cash Advance</span>
            <span v-if="iarTypeID==4">Gas Charge</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="PurposeDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text style="max-height: 900px">
          <div class="mt-5 mb-2">
            <span class="text-h6">Inspection and Acceptance</span>
          </div>
            <v-form @submit.prevent ref="Formref">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    dense
                    v-model="formData.SupplierName"
                    label="Supplier/s"
                    color="header"
                  ></v-text-field>
                  <v-text-field
                    dense
                    v-model="formData.InvoiceNo"
                    label="Invoice No."
                    color="header"
                  ></v-text-field>

                  <v-menu
                    dense
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    :return-value.sync="formData.DateInvoice"
                    transition="scale-transition"
                    offset-y
                    class="mb-4"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        dense
                        v-model="formData.DateInvoice"
                        label="Invoice Date"
                        color="header"
                        readonly
                        v-bind="attrs"
                        multiple
                        clearable
                        v-on="on"
                      >
                      <template
                      slot='selection'
                      slot-scope='data'>
                      <v-chip
                      @click="remove(data.item, 'invoice')"> 
                      {{data.item}}
                      </v-chip>
                      </template>
                      </v-combobox>
                    </template>
                    <v-date-picker
                      v-model="formData.DateInvoice"
                      multiple
                      no-title
                      scrollable
                      color="header"
                    >
                      <v-spacer></v-spacer>
                      <v-btn outlined color="grey darken-2" @click="menu1 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        dark
                        color="header"
                        @click="$refs.menu1.save(formData.DateInvoice)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>

                  <v-menu
                    dense
                    ref="menu4"
                    v-model="menu4"
                    :close-on-content-click="false"
                    :return-value.sync="formData.DateDelivered"
                    transition="scale-transition"
                    offset-y
                    class="mb-4"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        dense
                        v-model="formData.DateDelivered"
                        label="Date Delivered"
                        readonly
                        color="header"
                        v-bind="attrs"
                        multiple
                        clearable
                        v-on="on"
                      >
                      <template
                      slot='selection'
                      slot-scope='data'>
                      <v-chip
                      @click="remove(data.item, 'delivered')"> 
                      {{data.item}}
                      </v-chip>
                      </template>
                      </v-combobox>
                    </template>
                    <v-date-picker
                      v-model="formData.DateDelivered"
                      multiple
                      no-title
                      scrollable
                      color="header"
                    >
                      <v-spacer></v-spacer>
                      <v-btn outlined color="grey darken-2" @click="menu4 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        dark
                        color="header"
                        @click="$refs.menu4.save(formData.DateDelivered)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  <br />
                  <div class="font-weight-bold font-size-18">Inspection</div>
                  <v-divider class="mb-2"></v-divider>
                  <v-checkbox
                    v-model="formData.IsInspected"
                    color="green darken-2"
                    class=""
                  >
                    <template v-slot:label>
                      <div>Inspected</div>
                    </template>
                  </v-checkbox>
                  <v-menu
                    dense
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :return-value.sync="formData.DateInspectedStart"
                    transition="scale-transition"
                    offset-y
                    class="mb-4"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        dense
                        v-model="formData.DateInspectedStart"
                        :rules="[formRules.required]"
                        label="Date Inspected (Start)"
                        readonly
                        color="header"
                        v-bind="attrs"
                        v-on="on"
                      ></v-combobox>
                    </template>
                    <v-date-picker
                      v-model="formData.DateInspectedStart"
                      no-title
                      scrollable
                      color="header"
                    >
                      <v-spacer></v-spacer>
                      <v-btn outlined color="grey darken-2" @click="menu2 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        dark
                        color="header"
                        @click="$refs.menu2.save(formData.DateInspectedStart)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>

                  <v-menu
                    dense
                    ref="menu3"
                    v-model="menu3"
                    :close-on-content-click="false"
                    :return-value.sync="formData.DateInspectedEnd"
                    transition="scale-transition"
                    offset-y
                    class="mb-10"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        dense
                        v-model="formData.DateInspectedEnd"
                        :rules="[formRules.required]"
                        label="Date Inspected (End)"
                        color="header"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-combobox>
                    </template>
                    <v-date-picker
                      v-model="formData.DateInspectedEnd"
                      no-title
                      scrollable
                      color="header"
                    >
                      <v-spacer></v-spacer>
                      <v-btn outlined color="header" @click="menu3 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        dark
                        color="header"
                        @click="$refs.menu3.save(formData.DateInspectedEnd)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  <br />
                  <div class="font-weight-bold font-size-18">Acceptance</div>
                  <v-divider class="mb-2"></v-divider>
                  <v-radio-group v-model="formData.Status" class="">
                    <v-radio
                      label="Complete Delivery"
                      color="green darken-2"
                      value="Complete"
                    ></v-radio>

                    <v-radio
                      label="Partial Delivery"
                      color="green darken-2"
                      value="Partial"
                    ></v-radio>
                  </v-radio-group>
                  <v-menu
                    dense
                    ref="menu5"
                    v-model="menu5"
                    :close-on-content-click="false"
                    :return-value.sync="formData.DateReceivedStart"
                    transition="scale-transition"
                    offset-y
                    class="mb-10"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        dense
                        v-model="formData.DateReceivedStart"
                        :rules="[formRules.required]"
                        label="Date Received (Start)"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        color="header"
                      ></v-combobox>
                    </template>
                    <v-date-picker
                      v-model="formData.DateReceivedStart"
                      no-title
                      scrollable
                      color="header"
                    >
                      <v-spacer></v-spacer>
                      <v-btn outlined color="grey darken-2" @click="menu5 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        dark
                        color="header"
                        @click="$refs.menu5.save(formData.DateReceivedStart)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  <v-menu
                    dense
                    ref="menu6"
                    v-model="menu6"
                    :close-on-content-click="false"
                    :return-value.sync="formData.DateReceivedEnd"
                    transition="scale-transition"
                    offset-y
                    class="mb-10"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        dense
                        v-model="formData.DateReceivedEnd"
                        :rules="[formRules.required]"
                        label="Date Received (End)"
                        color="header"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-combobox>
                    </template>
                    <v-date-picker
                      v-model="formData.DateReceivedEnd"
                      no-title
                      scrollable
                      color="header"
                    >
                      <v-spacer></v-spacer>
                      <v-btn outlined color="grey darken-2" @click="menu6 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        dark
                        color="header"
                        @click="$refs.menu6.save(formData.DateReceivedEnd)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  <v-autocomplete
                    v-model="formData.RecieverUserID"
                    item-text="name"
                    item-value="UserID"
                    :rules="[formRules.required]"
                    :items="users"
                    dense
                    class="mb-4"
                    label="Select Receiver"
                    color="header"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" outlined @click="PurposeDialog = false">
            Cancel
          </v-btn>
          <v-btn color="green darken-2" dark @click="submit()" :loading="submitLoading">
            <v-icon class="mr-1">mdi-check-circle</v-icon>
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <v-dialog v-model="dialogSubItems" width="1000" persistent>
        <v-card>
          <v-card-title class="white--text text-h5 green darken-2">
            IAR Sub-Items
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeDialogSubItems()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-container>
            <v-form @submit.prevent ref="Formref">
            <v-row class="">
              <v-col cols="12">
                <v-data-table
                  :headers="sub_headers"
                  :items="subItemList"
                  disable-pagination
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <span class="green--text text--darken-2"
                        >List Of Sub Items</span
                      >
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-2"
                        dark
                        depressed
                        @click="dialogAddSubItems = true"
                      >
                      <v-icon left>
                        mdi-plus
                      </v-icon>
                        Add Item
                      </v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.ItemName`]="{ item }">
                  <v-edit-dialog
                    large
                    :return-value.sync="item.ItemName"
                  >
                    {{ item.ItemName }}
                  <template v-slot:input>
                      <v-textarea v-model="item.ItemName">
                      </v-textarea>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.itemDescription`]="{ item }">
                  <v-edit-dialog
                    large
                    :return-value.sync="item.ItemDescription"
                  >
                    {{ item.ItemDescription }}
                    <template v-slot:input>
                      <v-textarea v-model="item.ItemDescription">
                      </v-textarea>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.UnitCost`]="{ item }">
                  <v-edit-dialog
                    large
                    :return-value.sync="item.UnitCost"
                  >
                    ₱ {{ formatPrice(item.UnitCost) }}
                    <template v-slot:input>
                      <v-textarea v-model="item.UnitCost"> </v-textarea>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.Qty`]="{ item }">
                  <v-edit-dialog
                    large
                    :return-value.sync="item.Qty"
                  >
                  {{ item.Qty }}
                  <template v-slot:input>
                      <v-textarea v-model="item.Qty"> </v-textarea>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.Amount`]="{ item }">
                  <span class="text-no-wrap">
                    ₱
                    {{
                      formatPrice(
                        parseFloat(item.UnitCost) *
                          parseFloat(item.Qty)
                      )
                    }}
                  </span>
                </template>
                <template v-slot:[`item.Action`]="{ item }">
                  <v-btn 
                  small
                  color="red"
                  dark
                  outlined
                  @click="removeSubItem(item)">
                    Delete
                  </v-btn>
                </template>
                </v-data-table>
              </v-col>
              <v-col cols="12">
                Amount: <strong>₱ {{formatPrice(currentSubItemAmount())}}</strong><br />
                Balance: <strong>₱ {{formatPrice(itemBalance)}}</strong>
              </v-col>
            </v-row>


            </v-form>
            </v-container>
          </v-card-text>

          <v-divider></v-divider>
          
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-2" outlined @click="closeDialogSubItems()">
              Cancel
            </v-btn>
            <v-btn color="green darken-2" dark @click="saveSubItems()">
              <v-icon class="mr-1">mdi-check-circle</v-icon>
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogAddSubItems" width="400">
        <v-card>
          <v-card-title class="white--text text-h5 green darken-2">
            Add New Sub item
            <v-spacer></v-spacer>
            <v-btn color="white darken-2" text @click="dialogAddSubItems = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-container>
            <v-form @submit.prevent ref="FormrefSubItems">
            <v-row class="">
              <v-col cols="12">
                <v-text-field
                    dense
                    v-model="subItem.ItemName"
                    color="header"
                    :rules="[formRules.required]"
                    label="Item Name"
                    class="my-3"
                  ></v-text-field>
                <v-text-field
                    dense
                    v-model="subItem.ItemDescription"
                    :rules="[formRules.required]"
                    label="Item Description"
                    class="mb-3"
                    color="header"
                  ></v-text-field>

                  <v-autocomplete
                    v-model="subItem.UnitMeasure"
                    item-text="UnitMeasureDescription"
                    item-value="UnitMeasureID"
                    :rules="[formRules.required]"
                    :items="unitmeasureList"
                    return-object
                    dense
                    class="mb-4"
                    label="Unit Measure"
                    color="header"
                  >
                  </v-autocomplete>
                  <v-text-field
                    dense
                    v-model="subItem.UnitCost"
                    :rules="[formRules.required, subItem.UnitCost > 0, checkBalance(subItem.Qty, subItem.UnitCost)]"
                    label="Unit Cost"
                    type="number"
                    class="mb-3"
                    color="header"
                  ></v-text-field>
                  <v-text-field
                    dense
                    v-model="subItem.Qty"
                    :rules="[formRules.required, subItem.Qty > 0, checkBalance(subItem.Qty, subItem.UnitCost)]"
                    label="Quatity"
                    type="number"
                    class="mb-3"
                    color="header"
                  ></v-text-field>
              </v-col>
            </v-row>


            </v-form>
            </v-container>
          </v-card-text>

          <v-divider></v-divider>
          
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-2" text @click="dialogAddSubItems = false">
              Cancel
            </v-btn>
            <v-btn color="green darken-2" class="white--text" @click="addSubItem()">
              Add Item
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>
    
    <script>
export default {
  props: {
    prData: {},
  },
  data: () => ({
    notes: "",
    tempGrandTotal: null,
    EnableGrandtotal: false,
    dialog: false,
    PurposeDialog: false,
    dialogSubItems: false,
    dialogAddSubItems: false,
    dialogModel: false,
    iarTypeID: null,
    formData: {
      Lists: [],
      InvoiceNo: null,
      DateInspectedStart: null,
      DateInspectedEnd: null,
      DateInvoice: [],
      DateDelivered: null,
      Status: null,
      IsInspected: null,
      DateReceivedStart: null,
      DateReceivedEnd: null,
      RecieverUserID: null,
    },
    subItem: {},
    unitmeasureList: {},
    menu1: false,
    menu2: false,
    menu3: false,
    menu4: false,
    menu5: false,
    menu6: false,
    users: [],
    itemList: [],
    subItemList: [],
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Added!",
      message: "",
      top: 10,
    },
    headers: [
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "itemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        sortable: false,
      },
      { text: "Unit Cost", value: "UnitCost", sortable: false, align: 'end' },
      { text: "Actual Amount", value: "ActualAmount", sortable: false, align: 'end' },
      { text: "Qty (Overall)", value: "OverallQty", sortable: false, align: 'center' },
      { text: "Qty (Allowable)", value: "allowableQty", sortable: false, align: 'center' },
      { text: "Qty (IAR)", value: "IARQTY", sortable: false, align: 'center' },
      { text: "Qty (Editable)", value: "Qty", sortable: false, align: 'center' },
      { text: "Amount", value: "Amount", sortable: false, align: 'end' },
      { text: "Balance", value: "Balance", sortable: false, align: 'end' },
      { text: "Sub Items", value: "subItems", sortable: false, align: "center" },
    ],
    sub_headers: [
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "itemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        sortable: false,
        align: 'center'
      },
      { text: "Unit Cost", value: "UnitCost", sortable: false, align: 'end' },
      { text: "Quantity", value: "Qty", sortable: false, align: 'center' },
      { text: "Amount", value: "Amount", sortable: false, align: 'end' },
      { text: "Action", value: "Action", sortable: false, align: 'center' },
    ],
    deliveryterm: [],
    supplier: [],
    dod: [],
    mop: [],
    paymentterm: [],
    pod: [],
    IARItemID: null,
    itemBalance: 0,
    submitLoading: false,
  }),
  watch: {
    prData: {
      handler(data) {
        if (data.PRID) {
          if(!this.dialog){
          this.getItems();
          this.getPOUtil();
          }
          this.dialogModel = true;
        }
      },
      deep: true,
    },
      itemList: {
      handler(data) {
        this.EnableGrandtotal = false;
        data.forEach(item => {
          if (item.UnitCost == 0) {
          this.EnableGrandtotal = true;
          return;
        }
        });
        // console.log(data)
      },
      deep: true,
    },
  },
  mounted() {
    this.getCategory();
  },
  methods: {
    getPOUtil() {
      let data = new FormData();
      this.axiosCall("/get/all/POUtil", "POST", data).then((res) => {
        this.deliveryterm = res.data.data.deliveryterm;
        this.supplier = res.data.data.supplier;
        this.dod = res.data.data.dod;
        this.mop = res.data.data.mop;
        this.paymentterm = res.data.data.paymentterm;
        this.pod = res.data.data.pod;
      });
    },
    reimburse() {
      this.iarTypeID = 2;
      this.PurposeDialog = true;
    },
    cashAdvance() {
      this.iarTypeID = 3;
      this.PurposeDialog = true;
    },
    gasCharge() {
      this.iarTypeID = 4;
      this.PurposeDialog = true;
    },
    submit() {
      if (this.$refs.Formref.validate()) {
        let error = false;
        this.submitLoading = true;
        for (let list of this.formData.Lists) {
          if (
            parseFloat(list.editableQty) + parseFloat(list.IARQTY) >
              parseFloat(list.Qty) ||
            parseFloat(list.editableQty) == 0
          ) {
            error = true;
          }
        }
        if (error) {
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.header = "System Message";
          this.fadeAwayMessage.message =
            "Failed to continue. Please check the details.";
          this.PurposeDialog = false;
        } else {
          let data = new FormData();
          data.append("PRDATA", JSON.stringify(this.prData));
          data.append("List", JSON.stringify(this.formData.Lists));
          data.append("InvoiceNo", this.formData.InvoiceNo);
          data.append("DateInspectedStart", this.formData.DateInspectedStart);
          data.append("DateInspectedEnd", this.formData.DateInspectedEnd);
          data.append("RecieverUserID", this.formData.RecieverUserID);
          data.append("DateInvoice",  JSON.stringify({...this.formData.DateInvoice}));
          data.append("Status", this.formData.Status);
          data.append("IsInspected", this.formData.IsInspected == true ? 1 : 0);
          data.append("DateDelivered", JSON.stringify({...this.formData.DateDelivered}));
          data.append("DateReceivedStart", this.formData.DateReceivedStart);
          data.append("DateReceivedEnd", this.formData.DateReceivedEnd);
          data.append("IARType", this.iarTypeID);
          data.append("SupplierName", this.formData.SupplierName);
    // console.log(data)
          this.axiosCall("/iar/addOtherIAR", "POST", data).then((res) => {
            if(res.data.status){
              this.$refs.Formref.reset();
              this.PurposeDialog = false;
              this.fadeAwayMessage.show = true;
              this.fadeAwayMessage.type = "success";
              this.fadeAwayMessage.message = "Success!";
              this.fadeAwayMessage.header = "System Message";
              this.clickCloseMainDialogPR();
              window.open(this.apiUrl + "/iar/report/" + res.data.data + "/new");
            }
             this.submitLoading = false;
          });
        }
      }
    },
    clickCloseMainDialogPR() {
      this.formData.Lists = [];

      this.formData.dtID = null;
      this.formData.dodID = null;
      this.formData.mopID = null;
      this.formData.ptID = null;
      this.formData.SupplierID = null;
      this.formData.podID = null;
      this.itemList = [];
      this.dialogModel = false;
      this.eventHub.$emit("closePOModal", false);
    },
    getItems() {
      this.formData.Lists = [];
      let data = new FormData();
      data.append("PRID", this.prData.PRID);
      this.axiosCall("/pr/getItems/iar", "POST", data).then((res) => {
        this.itemList = res.data.data.result;
        this.users = res.data.data.users;
      });
    },
    remove(date, field) {
      let data = [];
      if(field == 'invoice'){
        data = this.formData.DateInvoice;
      }
      else if(field == 'delivered'){
        data = this.formData.DateDelivered;
      }
      var index = data.findIndex(x => x===date)
       data.splice(index,1);
    },
    currentSubItemAmount(){
      let total = 0;
      this.subItemList.forEach(item => {
        total += parseFloat(item.Qty) * parseFloat(item.UnitCost);
      });
    return Math.ceil(parseFloat(total) * 100) / 100
    },
    checkBalance(qty = 0, unitCost = 0){
      let amount = this.currentSubItemAmount();
      let balance = this.itemBalance;
      return (balance - amount) - (qty * unitCost) >= 0 || 'Insufficient Balance'
    },
    subItems(index, data) {
       this.subItemList = JSON.parse(JSON.stringify(this.itemList[index].SubItems));
       this.IARItemID = index;
       this.itemBalance = parseFloat(data.UnitCost) * (parseFloat(data.Qty) - parseFloat(data.IARQTY));
       this.dialogSubItems = true;
    },
    addSubItem(){
      if (this.$refs.FormrefSubItems.validate()) {
        // this.itemList[this.IARItemID].SubItems
        this.subItemList.push(
          {
            'StockPropertyNo': this.subItem.StockPropertyNo,
            'ItemName': this.subItem.ItemName,
            'ItemDescription': this.subItem.ItemDescription,
            'UnitMeasureID': this.subItem.UnitMeasure.UnitMeasureID,
            'UnitMeasureDescription': this.subItem.UnitMeasure.UnitMeasureDescription,
            'UnitCost': this.subItem.UnitCost,
            'Qty': this.subItem.Qty,
          });
        this.dialogAddSubItems = false;

        // console.log(this.subItemList);
        this.$refs.FormrefSubItems.reset();
      }
    },
    saveSubItems(){
      this.itemList[this.IARItemID].SubItems = this.subItemList;
      let total = 0;
      this.subItemList.forEach(item => {
          total += parseFloat(item.Qty) * parseFloat(item.UnitCost);
      });
    let actual_amount = parseFloat(this.itemList[this.IARItemID].Qty - this.itemList[this.IARItemID].IARQTY) * parseFloat(this.itemList[this.IARItemID].UnitCost);
    if(this.subItemList.length > 0){
      this.itemList[this.IARItemID].editableQty =  Math.ceil(parseFloat(total / actual_amount) * parseFloat(this.itemList[this.IARItemID].Qty - this.itemList[this.IARItemID].IARQTY) * 100) / 100;
    }
    else{
      this.itemList[this.IARItemID].editableQty = this.itemList[this.IARItemID].allowableQty;
    }
    this.subItemList = [];
    this.dialogSubItems = false;
    },
    getCategory() {
      this.axiosCall("/getCategory", "GET").then((res) => {
        //this.categoryList = res.data.data.category;
        this.unitmeasureList = res.data.data.unitmeasure;
      });
    },
    removeSubItem(item){
      let index = this.subItemList.indexOf(item);
      if (index > -1) {
        this.subItemList.splice(index, 1);
      }
    },
    closeDialogSubItems(){
      this.subItemList = [];
      this.dialogSubItems = false;
    }
  },
};
</script>
        
    <style scoped>
.v-dialog--fullscreen,
.poModal {
  overflow-x: hidden !important;
}
</style>
